import React from "react";
import { Link } from "gatsby";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import Layout from "../components/layout";
import HeaderLight from "./../components/headerLight";
import Footer from "./../components/footer";

require("../css/staticPages.css");


class CancellationPolicy extends React.Component {
    render() {
        return (
            <div>
                 <HeaderLight pathname="/privacy-policy" />
                 <div className="container NC-vision1 ">
                    <div className="row mar-top-btm">
                        <div className="col-sm-12 pad0 ">
                        <div> <h3 className="bannerHeading">Cancellation Policy</h3> </div>

                        <div className="row eachRow">
                                {/* <h5 className="subheading">Cancellation by Site Customer{" "}</h5> */}
                                <p><span className="pleaseNote">All Sellers</span>  must accept cancellations, returns, refunds and adjustments in accordance with the policies and any terms, conditions, and policies that appear on our Product Listing at the time of the applicable order.</p>
                        </div>

                        <div className="row eachRow">
                            <p><h3>Cancellations, Returns & Refunds</h3></p>
                        </div>

                            <div className="row eachRow">
                            <p>The Policy envisages the terms and conditions under which:</p>
                            </div>

                            <div className="row eachRow">
                            
                                <ol className="listitem" >
                                    <li> You are permitted to return goods purchased by you, subject to a valid reason;</li>
                                    <li> a refund may be obtained for goods purchased by you, subject to certain conditions ;</li>
                                    <li>  you may cancel orders for goods purchased by you within a stipulated period of time"</li>
                                </ol>
                                
                               
                               
                            </div>

                            <div className="row eachRow">
                            <p>It is important to note while the Company makes best efforts to ensure that returns, refunds and cancellations are available to the users of the Website, the Company has to place certain restrictions on the same in order to meet its business, legal and contractual obligations. Return, refunds or cancellations may also vary from product to product, in which case each such good sold there under will have specific guidelines which will determine the terms and conditions related to return, refunds or cancellations (the “Guidelines”). In each such case, the Guidelines shall prevail over this Policy and it is important for you to acquaint yourself with the applicable Guidelines while making a purchase on the Website.</p>
                            </div>
                            
                            <div className="row eachRow">
                            <p><h3>What I can return and when?</h3></p>
                            </div>

                            <div className="row eachRow">
                            <ol className="listitem">

                            <li> You receive a faulty or defective product</li>
                            <li> You receive a product that is different from what you ordered</li>
                            </ol>
                            </div>

                            <div className="row eachRow">
                            <p>
                           <h3> What products can NOT be returned?</h3>
                            </p>
                           </div>

                           <div className="row eachRow">

                           <ol className="listitem">
                           <li> Items that have been used.</li>
                           <li> Items with tampered label/ damaged packaging.</li>
                           </ol>

                         </div>

                            <div className="row eachRow">
                            <p>
                           <h3> Conditions for returning products</h3>
                            </p>
                           </div>

                           <div className="row eachRow">
                            <p>
                            <ol className="listitem">
                         <li> The products must be returned in the original condition they were received in along with any bills, labels etc;</li>  
                           <li> Products must be in their original form and unsoiled;</li> 
                           <li> Brand packaging should be intact;</li> 
                            <li>Replacement of a product will be subject to availability of the product on the website at that time.</li> 
                            </ol>
                            </p>
                           </div>
                          
                           <div className="row eachRow">
                           <p>To raise a dispute, please send an email to info@nativecircle.in. Your email must contain name of the product and the order ID along with a detailed description of the nature of the concern. This must be done within 3 days of receipt of the product. Disputes registered after 3 days of delivery shall be entertained. If the reason for return is a valid one, then you must send the product back to the vendor within 7 days agreeing to the refund."</p>
                           </div>

                           <div className="row eachRow">
                           <p><h2>Customer Claims and Disputes</h2></p>
                           </div>

                           <div className="row eachRow">
                           <p>If we inform you that we have received a claim, chargeback or other dispute concerning one of your Transactions, you will deliver any reasonable information we request to confirm you fulfilled the Transaction. If you fail to comply with the prior sentence, or if the claim chargeback, or dispute is not caused by (i) our failure to make your Order Information available as it was received by us or (ii) credit card fraud as described in the Payments Guidelines, then you will promptly reimburse us for the amount of the customer purchase (including the Purchase Price and any taxes ) and all associated credit card association, bank or other payment processing, re-presentment and penalty fees associated with the original purchase and any chargeback or refund, in each case to the extent paid or payable by us.</p>
                           </div>

                           <div className="row eachRow">
                           <p>T&C</p>
                           </div>

                           <div className="row eachRow">
                           <p><h3>Introduction</h3></p>
                           </div>

                           <div className="row eachRow">
                           <p>These terms and conditions govern your use of this website; by using this website, you accept these terms and conditions in full. If you disagree with these terms and conditions or any part of these terms and conditions, you must not use this website.</p>
                           </div>

                           <div className="row eachRow">
                           <p>You must be at least 18 years of age to use this website.  By using this website and by agreeing to these terms and conditions you warrant and represent that you are at least 18 years of age.</p>
                           </div>

                           <div className="row eachRow">
                           <p>This website uses cookies.  By using this website and agreeing to these terms and conditions, you consent to our Native Circle's use of cookies in accordance with the terms of Native Circle's privacy policy / cookies policy.</p>
                           </div>

                           <div className="row eachRow">
                            <h4>License to use website</h4>
                           </div>

                           <div className="row eachRow">
                           <p> Unless otherwise stated, Native Circle and/or its licensors own the intellectual property rights in the website and material on the website. Subject to the license below, all these intellectual property rights are reserved.</p>
                           </div>

                            <div className="row eachRow">
                            <p> You may view, download for caching purposes only, and print pages [or [OTHER CONTENT]] from the website for your own personal use, subject to the restrictions set out below and elsewhere in these terms and conditions. </p>
                            </div>

                            <div className="row eachRow">
                           <p> You must not:  </p>
                           </div>

                           <div className="row eachRow">
                           <p>republish material from this website (including republication on another website); </p>
                           </div>

                           <div className="row eachRow">
                           <p>sell, rent or sub-license material from the website; </p>
                           </div>

                           <div className="row eachRow">
                           <p> show any material from the website in public;</p>
                           </div>

                           <div className="row eachRow">
                           <p> reproduce, duplicate, copy or otherwise exploit material on this website for a commercial purpose;</p>
                           </div>

                           <div className="row eachRow">
                           <p>edit or otherwise modify any material on the website; or </p>
                           </div>

                           <div className="row eachRow">
                           <p>redistribute material from this website [except for content specifically and expressly made available for redistribution. </p>
                           </div>
                            
                           <div className="row eachRow">
                           <h4> Acceptable use</h4> 
                           </div>

                           <div className="row eachRow">
                           <p> You must not use this website in any way that causes, or may cause, damage to the website or impairment of the availability or accessibility of the website; or in any way which is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose or activity. </p>
                           </div>
                            
                           <div className="row eachRow">
                           <p> You must not conduct any systematic or automated data collection activities (including without limitation scraping, data mining, data extraction and data harvesting) on or in relation to this website without Native Circle's express written consent. </p>
                           </div>

                           <div className="row eachRow">
                           <p> You must not use this website to transmit or send unsolicited commercial communications. </p>
                           </div>

                           <div className="row eachRow">
                           <p> You must not use this website for any purposes related to marketing without Native Circle's express written consent. </p>
                           </div>

                           <div className="row eachRow">
                           <h4>Restricted access</h4> 
                           </div>

                           
                           <div className="row eachRow">
                           <p>Access to certain areas of this website is restricted.  Native Circle reserves the right to restrict access to other areas of this website, or indeed this entire website, at Native Circle's discretion.</p>
                           </div>

                           <div className="row eachRow">
                           <p>If Native Circle provides you with a user ID and password to enable you to access restricted areas of this website or other content or services, you must ensure that the user ID and password are kept confidential. </p>
                           </div>


                           <div className="row eachRow">
                           <p> Native Circle may disable your user ID and password in Native Circle's sole discretion without notice or explanation.</p>
                           </div>

                           <div className="row eachRow">
                           <h4>User content</h4>
                           </div>

                           <div className="row eachRow">
                           <p>In these terms and conditions, “your user content” means material (including without limitation text, images, audio material, video material and audio-visual material) that you submit to this website, for whatever purpose.</p>
                           </div>

                           <div className="row eachRow">
                           <p>You grant to Native Circle a worldwide, irrevocable, non-exclusive, royalty-free license to use, reproduce, adapt, publish, translate and distribute your user content in any existing or future media.  You also grant to Qtrove the right to sub-license these rights, and the right to bring an action for infringement of these rights</p>
                           </div>

                           <div className="row eachRow">
                           <p>Your user content must not be illegal or unlawful, must not infringe any third party's legal rights, and must not be capable of giving rise to legal action whether against you or Native Circle or a third party (in each case under any applicable law). </p>
                           </div>

                           <div className="row eachRow">
                           <p>You must not submit any user content to the website that is or has ever been the subject of any threatened or actual legal proceedings or other similar complaint. </p>
                           </div>

                           <div className="row eachRow">
                           <p>Native Circle reserves the right to edit or remove any material submitted to this website, or stored on Native Circle 's servers, or hosted or published upon this website.</p>
                           </div>

                           <div className="row eachRow">
                           <p>Notwithstanding Native Circle's rights under these terms and conditions in relation to user content, Native Circle does not undertake to monitor the submission of such content to, or the publication of such content on, this website.</p>
                           </div>

                           <div className="row eachRow">
                           <h4>No warranties</h4>
                           </div>

                           <div className="row eachRow">
                           <p>This website is provided “as is” without any representations or warranties, express or implied.  Native Circle makes no representations or warranties in relation to this website or the information and materials provided on this website. </p>
                           </div>

                           <div className="row eachRow">
                           <p>Without prejudice to the generality of the foregoing paragraph, Native Circle does not warrant that:</p>
                           </div>

                           <div className="row eachRow">
                           <p>this website will be constantly available, or available at all; or
the information on this website is complete, true, accurate or non-misleading.</p>
                           </div>

                           <div className="row eachRow">
                           <p>Nothing on this website constitutes, or is meant to constitute, advice of any kind.  [If you require advice in relation to any [legal, financial or medical] matter you should consult an appropriate professional.</p>
                           </div>

                           <div className="row eachRow">
                           <h4>Limitations of liability</h4> 
                           </div>

                           <div className="row eachRow">
                           <p>Native Circle will not be liable to you (whether under the law of contact, the law of torts or otherwise) in relation to the contents of, or use of, or otherwise in connection with, this website:</p>
                           </div>

                           <div className="row eachRow">
                           <p>to the extent that the website is provided free-of-charge, for any direct loss;
for any indirect, special or consequential loss; or
for any business losses, loss of revenue, income, profits or anticipated savings, loss of contracts or business relationships, loss of reputation or goodwill, or loss or corruption of information or data.</p>
                           </div>


                           <div className="row eachRow">
                           <p>These limitations of liability apply even if Native Circle has been expressly advised of the potential loss.</p>
                           </div>


                           <div className="row eachRow">
                           <h4>Exceptions</h4>
                           </div>


                           <div className="row eachRow">
                           <p>Nothing in this website disclaimer will exclude or limit any warranty implied by law that it would be unlawful to exclude or limit; and nothing in this website disclaimer will exclude or limit Native Circle 's liability in respect of any:</p>
                           </div>

                           <div className="row eachRow">
                           <p>death or personal injury caused by Native Circle 's negligence;
fraud or fraudulent misrepresentation on the part of Native Circle; or
matter which it would be illegal or unlawful for Native Circle to exclude or limit, or to attempt or purport to exclude or limit, its liability.</p>
                           </div>


                           <div className="row eachRow">
                           <h4>Reasonableness</h4>
                           </div>

                           <div className="row eachRow">
                           <p>By using this website, you agree that the exclusions and limitations of liability set out in this website disclaimer are reasonable. </p>
                           </div>

                           <div className="row eachRow">
                           <p>If you do not think they are reasonable, you must not use this website.</p>
                           </div>


                           <div className="row eachRow">
                           <h4>Other parties</h4>
                           </div>


                           <div className="row eachRow">
                           <p>You accept that, as a limited liability entity, Native Circle has an interest in limiting the personal liability of its officers and employees.  You agree that you will not bring any claim personally against Native Circle 's officers or employees in respect of any losses you suffer in connection with the website.</p>
                           </div>


                           <div className="row eachRow">
                           <p>Without prejudice to the foregoing paragraph, you agree that the limitations of warranties and liability set out in this website disclaimer will protect Native Circle 's officers, employees, agents, subsidiaries, successors, assigns and sub-contractors as well as Native Circle.</p>
                           </div>


                           <div className="row eachRow">
                           <h4> Unenforceable provisions</h4>
                           </div>


                           <div className="row eachRow">
                           <p>If any provision of this website disclaimer is, or is found to be, unenforceable under applicable law, that will not affect the enforceability of the other provisions of this website disclaimer.</p>
                           </div>


                           <div className="row eachRow">
                           <h4>Indemnity</h4>
                           </div>

                           <div className="row eachRow">
                           <p>You hereby indemnify Native Circle and undertake to keep Native Circle indemnified against any losses, damages, costs, liabilities and expenses (including without limitation legal expenses and any amounts paid by Native Circle to a third party in settlement of a claim or dispute on the advice of Native Circle 's legal advisers) incurred or suffered by Native Circle arising out of any breach by you of any provision of these terms and conditions, or arising out of any claim that you have breached any provision of these terms and conditions.</p>
                           </div>

                           <div className="row eachRow">
                           <h3>Breaches of these terms and conditions</h3> 
                           </div>

                           <div className="row eachRow">
                           <p> Without prejudice to Native Circle 's other rights under these terms and conditions, if you breach these terms and conditions in any way, Native Circle may take such action as Native Circle deems appropriate to deal with the breach, including suspending your access to the website, prohibiting you from accessing the website, blocking computers using your IP address from accessing the website, contacting your internet service provider to request that they block your access to the website and/or bringing court proceedings against you.</p>
                           </div>

                           <div className="row eachRow">
                           <p><h3>Variation</h3></p>
                           </div>

                           <div className="row eachRow">
                           <p>Native Circle may revise these terms and conditions from time-to-time.  Revised terms and conditions will apply to the use of this website from the date of the publication of the revised terms and conditions on this website.  Please check this page regularly to ensure you are familiar with the current version.</p>
                           </div>


                           <div className="row eachRow">
                           <p><h4>Assignment</h4></p>
                           </div>


                           <div className="row eachRow">
                           <p>Native Circle may transfer, sub-contract or otherwise deal with Native Circle 's rights and/or obligations under these terms and conditions without notifying you or obtaining your consent.</p>
                           </div>


                           <div className="row eachRow">
                           <p>You may not transfer, sub-contract or otherwise deal with your rights and/or obligations under these terms and conditions. </p>
                           </div>


                           <div className="row eachRow">
                           <p><h4>Severability</h4></p>
                           </div>


                           <div className="row eachRow">
                           <p>If a provision of these terms and conditions is determined by any court or other competent authority to be unlawful and/or unenforceable, the other provisions will continue in effect.  If any unlawful and/or unenforceable provision would be lawful or enforceable if part of it were deleted, that part will be deemed to be deleted, and the rest of the provision will continue in effect. </p>
                           </div>

                           <div className="row eachRow">
                           <p><h4>Entire agreement</h4></p>
                           </div>

                           <div className="row eachRow">
                           <p>These terms and conditions, together with documents, constitute the entire agreement between you and Native Circle in relation to your use of this website, and supersede all previous agreements in respect of your use of this website.</p>
                           </div>

                           <div className="row eachRow">
                           <p><h4>Law and jurisdiction</h4></p>
                           </div>

                           <div className="row eachRow">
                           <p>These terms and conditions will be governed by and construed in accordance with [GOVERNING LAW], and any disputes relating to these terms and conditions will be subject to the [non-]exclusive jurisdiction of the Bangalore High Court</p>
                           </div>

                           <div className="row eachRow">
                           <p><h4>Shipping Policy</h4></p>
                           </div>


                           <div className="row eachRow">
                           <p>We currently deliver across India to all metros and certain non-metro cities. We have tied up with a reliable logistics partner that ensures reliable delivery of non-perishable items across cities. For perishable goods, we have our own logistics team that will pick your products from the vendor and hand deliver it to you (This service is currently available only within Bangalore). We try our very best to ensure that there are no delays in shipments, but sometimes it's beyond our control.</p>
                           </div>

                           <div className="row eachRow">
                           <p><h4> Shipping Time</h4></p>
                           </div>

                           <div className="row eachRow">
                           <p>Since most of the products are made to order, we allow the vendors to decide on the lead time they require. This time is indicated alongside each product and reflects in the checkout page. Typically the lead time is 2-4 days. 
The logistics partner gets a notification once the vendor has your product ready for dispatch. They will then pick it up from the vendor and deliver it to you between 8am to 9pm on all days Monday to Saturday.</p>
                           </div>


                           <div className="row eachRow">
                           <p>We ship on all days (Monday to Saturday) except Sundays and public holidays.</p>
                           </div>


                           <div className="row eachRow">
                           <p>If you feel that the packaging of the product is tampered or damaged at the time of delivery, please refuse to accept delivery of the product and contact us immediately at wecare@qtrove.com, mentioning your order reference number. We shall ensure a replacement delivery is made to you at the earliest.</p>
                           </div>



                           <div className="row eachRow">
                           <p>In case your shipment is delayed:</p>
                           </div>



                           <div className="row eachRow">
                           <p>In case there is a delay, please send us an email on wecare@qtrove.com with your order number.</p>
                           </div>


                           
                           <div className="row eachRow">
                           <p>In the unfortunate scenario that your shipment has been misplaced or lost enroute, we can either resend the order to you (if the same products are still in stock), or issue a credit note to you, or reimburse the amount completely to you.</p>
                           </div>


                           <div className="row eachRow">
                           <p><h4>Privacy</h4></p>
                           </div>

                           <div className="row eachRow">
                           <p>We are committed to safeguarding the privacy of our website visitors; this policy sets out how we will treat your personal information.</p>
                           </div>

                           <div className="row eachRow">
                           <p>Our website uses cookies.  By using our website and agreeing to this policy, you consent to our use of cookies in accordance with the terms of this policy.</p>
                           </div>

                           <div className="row eachRow">
                           
                            <h5>(1) What information do we collect? </h5> 
                          
                            
                           
                           </div>

                           <div className="row eachRow">
                           <p>We may collect, store and use the following kinds of personal information:</p>
                           

                           <ol className="listitem" >
                                   <li> information about your computer and about your visits to and use of this website (including your IP address, geographical location, browser type and version, operating system, referral source, length of visit, page views, website navigation);</li>
                                   <li> information relating to any transactions carried out between you and us on or in relation to this website, including information relating to any purchases you make of our goods or services;</li>
                                   <li> information that you provide to us for the purpose of registering with us;</li>
                                   <li> information that you provide to us for the purpose of subscribing to our website services, email      notifications and/or newsletters;</li>
                                   <li>any other information that you choose to send to us; and</li>
                                   <li>other information.</li>
                                   
                                </ol>
                                </div>


                            <div className="row eachRow">
                           <h5>(2) Cookies</h5> 
                            </div>

                            <div className="row eachRow">
                            A cookie consists of information sent by a web server to a web browser, and stored by the browser. The information is then sent back to the server each time the browser requests a page from the server. This enables the web server to identify and track the web browser.
We may use both “session” cookies and “persistent” cookies on the website.  We will use the session cookies to: keep track of you whilst you navigate the website. We will use the persistent cookies to: enable our website to recognise you when you visit.
                            
                            </div>
                            Session cookies will be deleted from your computer when you close your browser. Persistent cookies will remain stored on your computer until deleted, or until they reach a specified expiry date.

                            <div className="row eachRow">
                            We use Google Analytics to analyse the use of this website.  Google Analytics generates statistical and other information about website use by means of cookies, which are stored on users' computers.  The information generated relating to our website is used to create reports about the use of the website. Google will store this information.  Google's privacy policy is available at: http://www.google.com/privacypolicy.html.
                            </div>



                            <div className="row eachRow">
                            Our advertisers/payment services providers may also send you cookies.
                            </div>


                            <div className="row eachRow">
                            We publish Google Adsense interest-based advertisements on our website.  These are tailored by Google to reflect your interests.  To determine your interests, Google will track your behaviour across the web using cookies.  You can view, delete or add interest categories associated with your browser using Google's Ads Preference Manager, available at: http://www.google.com/ads/preferences/.  You can opt-out of the Adsense partner network cookie at: http://www.google.com/privacy_ads.html.  However, this opt-out mechanism uses a cookie, and if you clear the cookies from your browser your opt-out will not be maintained. To ensure that an opt-out is maintained in respect of a particular browser, you should use the Google browser plug-in available at: http://www.google.com/ads/preferences/plugin.
                            </div>

                            <div className="row eachRow">
                            Most browsers allow you to reject all cookies, whilst some browsers allow you to reject just third party cookies.  For example, in Internet Explorer you can refuse all cookies by clicking “Tools”, “Internet Options”, “Privacy”, and selecting “Block all cookies” using the sliding selector.  Blocking all cookies will, however, have a negative impact upon the usability of many websites[, including this one.
                            </div>


                            <div className="row eachRow">
                           <h5> (3) Using your personal information</h5>
                            </div>

                            <div className="row eachRow">
                            Personal information submitted to us via this website will be used for the purposes specified in this privacy policy or in relevant parts of the website.
                            </div>


                            <div className="row eachRow">
                            We may use your personal information to:
                            </div>

                            <div className="row eachRow">
                            
                            <ol className="listitem" >
                               <li> administer the website;</li>
                               <li> improve your browsing experience by personalising the website;</li>
                               <li> enable your use of the services available on the website;</li>
                               <li> send to you goods purchased via the website, and supply to you services purchased via the website;</li>
                               <li> send statements and invoices to you, and collect payments from you;</li>
                               <li> send you general (non-marketing) commercial communications;</li>
                               <li>  send you email notifications which you have specifically requested;</li>
                               <li>Native Circle frequently gives out offers to give registered and unregistered members the opportunity to win great discounts and vouchers. Information collected by us for such activities may include contact information and survey questions, which are used to notify contest winners and develop promotions and products respectively. As a member, you will also occasionally receive updates from us about special offers and new products, and in addition, you may look forward to receiving newsletters and exclusive promotions offering special deals. However, you may choose at any time to no longer receive these email messages;</li>
                               <li> provide third parties with statistical information about our users – but this information will not be used to identify any individual user;</li>
                               <li>deal with enquiries and complaints made by or about you relating to the website; and</li>
                               <li> other uses</li>
                            </ol>
                        </div>

                        <div className="row eachRow">
                        Where you submit personal information for publication on our website, we will publish and otherwise use that information in accordance with the license you grant to us.
                            
                            </div>
                          
                            <div className="row eachRow">
                            We will not without your express consent provide your personal information to any third parties for the purpose of direct marketing.
                            </div>

                            <div className="row eachRow">
                             All our website financial transactions are handled through our payment services provider, Razorpay. You can review the Razorpay privacy policy at https://razorpay.com/privacy/.  We will share information with  Razorpay only to the extent necessary for the purposes of processing payments you make via our website and dealing with complaints and queries relating to such payments.
                            </div>

                            <div className="row eachRow">
                           <h5> (4) Disclosures</h5>
                             </div>

                             <div className="row eachRow">
                             We may disclose information about you to any of our employees, officers, agents, suppliers or subcontractors insofar as reasonably necessary for the purposes as set out in this privacy policy.
                             </div>

                             <div className="row eachRow">
                             In addition, we may disclose your personal information:
                             </div>

                             <div className="row eachRow">
                            
                            <ol className="listitem" >
                            <li>to the extent that we are required to do so by law;</li>
                            <li> in connection with any legal proceedings or prospective legal proceedings;</li>
                            <li>in order to establish, exercise or defend our legal rights (including providing information to others for the purposes of fraud prevention and reducing credit risk);</li>
                            <li>to the purchaser (or prospective purchaser) of any business or asset which we are (or are contemplating) selling; and</li>
                            <li> to any person who we reasonably believe may apply to a court or other competent authority for disclosure of that personal information where, in our reasonable opinion, such court or authority would be reasonably likely to order disclosure of that personal information.</li>
                            <div className="row eachRow">
                            Except as provided in this privacy policy, we will not provide your information to third parties.
                             </div>
                             </ol>
                            </div>
                           
                            <div className="row eachRow">
                           <h5>(5) International data transfers</h5> 
                             </div>

                             <div className="row eachRow">
                             Information that we collect may be stored and processed in and transferred between any of the countries in which we operate in order to enable us to use the information in accordance with this privacy policy.
                             </div>


                             <div className="row eachRow">
                             Information which you provide may be transferred to countries which do not have data protection laws equivalent to those in force in our home jurisdiction.
                             </div>


                             <div className="row eachRow">
                             In addition, personal information that you submit for publication on the website will be published on the internet and may be available, via the internet, around the world.  We cannot prevent the use or misuse of such information by others.
                             </div>

                             <div className="row eachRow">
                             You expressly agree to such transfers of personal information
                             </div>

                             <div className="row eachRow">
                           <h5>(6) Security of your personal information</h5>  
                             </div>

                             <div className="row eachRow">
                             We will take reasonable technical and organisational precautions to prevent the loss, misuse or alteration of your personal information.
                             </div>

                             <div className="row eachRow">
                             We will store all the personal information you provide on our secure (password- and firewall- protected) servers. All electronic transactions you make to or receive from us will be encrypted [using SSL technology].
                             </div>

                             <div className="row eachRow">
                             Of course, data transmission over the internet is inherently insecure, and we cannot guarantee the security of data sent over the internet.
                             </div>

                             <div className="row eachRow">
                             You are responsible for keeping your password and user details confidential. We will not ask you for your password (except when you log in to the website).
                             </div>

                             <div className="row eachRow">
                           <h5>(7) Policy amendments</h5>  
                             </div>

                             
                             <div className="row eachRow">
                             We may update this privacy policy from time-to-time by posting a new version on our website. You should check this page occasionally to ensure you are happy with any changes. 
                             </div>


                             
                             <div className="row eachRow">
                             We may also notify you of changes to our privacy policy by email.
                             </div>




                             
                             <div className="row eachRow">
                             <h5>(8) Your rights</h5>
                             </div>

                             <div className="row eachRow">
                             You may instruct us to provide you with any personal information we hold about you. Provision of such information will be subject the payment of a fee. We may withhold such personal information to the extent permitted by law.
                            </div>


                             
                             <div className="row eachRow">
                             You may instruct us not to process your personal information for marketing purposes by email at any time.  In practice, you will usually either expressly agree in advance to our use of your personal information for marketing purposes, or we will provide you with an opportunity to opt-out of the use of your personal information for marketing purposes.
                             </div>

                             <div className="row eachRow">
                            <h5>(9) Third party websites</h5> 
                             </div>

                             <div className="row eachRow">
                             The website contains links to other websites. We are not responsible for the privacy policies or practices of third party websites.
                             </div>

                             <div className="row eachRow">
                            <h5>(10) Updating information</h5> 
                            </div>


                            <div className="row eachRow">
                            Please let us know if the personal information which we hold about you needs to be corrected or updated.
                            </div>

                            <div className="row eachRow">
                           <h5>(11)  Contact</h5> 
                            </div>

                            <div className="row eachRow">
                            If you have any questions about this privacy policy or our treatment of your personal information, please write to us by email to info@nativecircle.in

                            </div>

                        </div>
                        </div>
                        </div>
                 <Footer />
            </div>
        )
    }
}
export default CancellationPolicy